import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [environment, setEnvironment] = useState({} as any);
  useEffect(() => {
    async function fetchEnvironment() {
      if (process.env.NODE_ENV === "development") {
        setEnvironment(require('./environment.json'))
      } else {
        const environment = await (await fetch('./environment.json')).json();
        console.log(environment);
        setEnvironment(environment);
      }
    }
    fetchEnvironment();
  }, [])
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          CloudOS v3 is awesome!
        </a>
        <div>
          Common Environment variable:  {environment.COMMON_VAR}
        </div>
        <div>
          Unique Environment variable:  {environment.ENVIRONMENT}
        </div>
        <div>
          MIAO Environment variable:  {environment.MIAO}
        </div>
      </header>
    </div>
  );
}

export default App;
